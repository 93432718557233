import {
  SITE_FORM_DATA,
  SITE_FORM_ELEMENTS,
} from '../components/forms/SiteFormElements'
import {
  ORGANISATION_FORM_DATA,
  ORGANISATION_FORM_ELEMENTS,
} from '../components/forms/OrganisationFormElements'
import { Field, FormConfig, Option, Payload } from '../components/forms/AddEditRecord.type'
import React, { Dispatch, RefObject, SetStateAction } from 'react'
import {
  SERVICE_FORM_DATA,
  SERVICE_FORM_ELEMENTS,
} from '../components/forms/ServiceFormElements'
import SPUDAdvancedList, { SPUDAdvancedListProps } from '../components/General/SPUDAdvancedList'
import {
  AvatarIconWithStatusWrapper,
  AvatarIconWithStatusContent,
  Title,
  SuccessStatus,
  WarningStatus,
  ErrorStatus,
  Input,
  Toggle,
  Button,
  Text,
} from '@ix/ix-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faCheck, faExclamation, faHome, faTimes, faUsersCog } from '@fortawesome/free-solid-svg-icons'
import { SizeProp, IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  ControllerRenderProps, FieldValues, UseFormReturn,
} from 'react-hook-form'
import SPUDAutoComplete from '../components/General/SPUDAutoComplete'
import SPUDLocation, { SPUDLocationProps } from '../components/General/SPUDLocation'
import styled from 'styled-components'
import SPUDPostalAddress, { SPUDPostalAddressProps } from '../components/General/SPUDPostalAddress'
import SPUDContact, { SPUDContactProps } from '../components/General/SPUDContact'
import SPUDSocials, { SPUDSocialProps } from '../components/General/SPUDSocials'
import SPUDEmails, { SPUDEmailsProps } from '../components/General/SPUDEmails'
import { SPUDRecord } from '../../@types/SPUDRecord.type'
import SPUDRadioButtonGroup from '../components/General/SPUDRadioButtonGroup'
import SPUDAccreditation, { SPUDAccreditationProps } from '../components/General/SPUDAccreditation'
import SPUDTextArea from '../components/General/SPUDTextArea'
import SPUDServiceTypeClassification, {
  SPUDServiceTypeClassificationProps,
} from '../components/General/SPUDServiceTypeClassification'
import SPUDDatePicker from '../components/General/SPUDDatePicker'
import InputMask from 'react-input-mask'
import SPUDOpeningHours, {
  OpenHoursType,
  SPUDOpeningHoursProps,
} from '../components/General/OpeningHours/SPUDOpeningHours'
import { SPUDSiteRecordDetails } from '../../@types/Site.type'
import { SPUDOrganisationRecordDetails } from '../../@types/Organisation.type'
import { SPUDServiceRecordDetails } from '../../@types/Service.type'
import { LinkedRecord } from '../../@types/LinkedRecord.type'
import BulkUpdateRecordChangeReview from '../components/forms/BulkUpdateRecordChangeReview'
import { AgeGroupType, BulkServiceProviderUpdateType } from '../services/spud.service'
import SiteOpeningHours from '../components/General/OpeningHours/SiteOpeningHours'
import SPUDCrisisKeywordsAutocomplete, {
  SPUDCrisisKeywordsAutocompleteProps,
} from '../components/General/SPUDCrisisKeywordsAutocomplete'
import SPUDGeographicCoverage, {
  SPUDGeographicCoverageProps,
} from '../components/General/GeographicCoverage/SPUDGeographicCoverage'
import ReactSwitch from 'react-switch'
import SPUDAgeGroups, { SPUDAgeGroupsPropTypes } from '../components/SPUDAgeGroups'

/* eslint-disable camelcase */
export interface SpudRecord {
  id: string,
  details: Array<{data: Record<string, string>}>|[]
  allocated_user: number|null|undefined,
  datasets: Array<string>,
  date_started: string,
  last_modified: string,
  status: string,
  record: {
    id: number,
    record_type: string,
    iss_id: number|null|undefined
  }
}
/* eslint-enable camelcase */

const UNABLE_TO_ADD_RECORD_TYPES = ['service']

export const SPUDInputField = styled(Input)<{highlight?: boolean | undefined}>`
  div {
    margin: 0;
  }
  border: ${props => props.highlight ? '3px solid #ff9595' : '1px solid #3a8ae8'};
  margin: 0;
  :hover {
    border: ${props => props.highlight ? '3px solid #ff9595' : '1px solid #3a8ae8'};
  }
`

export const SPUDToggle = styled(Toggle)<{padding?: string}>`
  label {
    padding: ${props => props.padding || 0};
    font-size: 0.8em;
    color: #000;
  }

  border: ${props => (props.checked ? 'none' : '1px solid #888888')};
`

export const AdvancedFilterWrapper = styled.div`
  width: 30%;
`
export const SPUDFormFieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
`

export const getPageType = (recordType: string | undefined): string => {
  switch (recordType) {
  case 'site':
    return 'Site'
  case 'organisation':
    return 'Organisation'
  case 'service':
    return 'Service'
  default:
    return 'Record'
  }
}

export const formatAddEditTitle = (
  recordType: string | undefined,
  action: string | undefined,
  recordId?: string | number | null,
): string => {
  if (action === 'add') {
    return `${getPageType(recordType)} - Add`
  } else if (action === 'edit' && recordId) {
    return `${getPageType(recordType)} - Edit (${recordId})`
  }
  return getPageType(recordType)
}

export const fetchFormDataStructure = (recordType: string | undefined): Payload | undefined => {
  switch (recordType) {
  case 'site':
    return SITE_FORM_DATA
  case 'organisation':
    return ORGANISATION_FORM_DATA
  case 'service':
    return SERVICE_FORM_DATA
  }
}

export const CustomComponent = (
  { customComponent, props }: {customComponent: string, props: unknown},
): React.ReactElement => {
  switch (customComponent) {
  case 'SPUDAdvancedList':
    return <SPUDAdvancedList {...props as SPUDAdvancedListProps} />
  case 'SPUDLocation':
    return <SPUDLocation {...props as SPUDLocationProps} />
  case 'SPUDPostalAddress':
    return <SPUDPostalAddress {...props as SPUDPostalAddressProps }/>
  case 'SPUDContact':
    return <SPUDContact {...props as SPUDContactProps} />
  case 'SPUDSocials':
    return <SPUDSocials {...props as SPUDSocialProps} />
  case 'SPUDEmails':
    return <SPUDEmails {...props as SPUDEmailsProps} />
  case 'SPUDAccreditation':
    return <SPUDAccreditation {...props as SPUDAccreditationProps} />
  case 'SPUDServiceTypeClassification':
    return <SPUDServiceTypeClassification {...props as SPUDServiceTypeClassificationProps} />
  case 'SPUDGeographicCoverage':
    return <SPUDGeographicCoverage {...props as SPUDGeographicCoverageProps} />
  case 'SPUDOpeningHours':
    return <SPUDOpeningHours {...props as SPUDOpeningHoursProps} />
  case 'SPUDCrisisKeywordsAutocomplete':
    return <SPUDCrisisKeywordsAutocomplete {...props as SPUDCrisisKeywordsAutocompleteProps} />
  case 'SPUDAgeGroups':
    return <SPUDAgeGroups {...props as SPUDAgeGroupsPropTypes} />
  default:
    return <span>Custom component not found</span>
  }
}

const fetchCorrectStatusIcon = (status: string): React.ReactElement | null => {
  switch (status) {
  case 'success':
    return (
      <SuccessStatus>
        <FontAwesomeIcon icon={faCheck as IconProp}/>
      </SuccessStatus>
    )
  case 'warning':
    return (
      <WarningStatus>
        <FontAwesomeIcon icon={faExclamation as IconProp}/>
      </WarningStatus>
    )
  case 'error':
    return (
      <ErrorStatus>
        <FontAwesomeIcon icon={faTimes as IconProp}/>
      </ErrorStatus>
    )
  default:
    return null
  }
}
type ErrorType = {
  [x:string]: {
    [y: string]: {
      type: string,
    }
  }
}

export const validateSections = (
  errors: ErrorType,
  formGroup: FormConfig,
  isSubmitted: boolean,
): 'success' | 'warning' | 'error' => {
  const errorFields = Object.keys(errors) || ''
  if (
    formGroup.fields.find(field => errorFields.includes(field.name))
  ) {
    return 'error'
  } else {
    if (!isSubmitted) {
      return 'warning'
    } else {
      return 'success'
    }
  }
}

export const generateQuickAccessToolbar = (
  formGroups: Array<FormConfig> | null,
  errors: ErrorType, isSubmitted: boolean): Array<{
  name: string,
  ref: RefObject<HTMLDivElement>,
  element: React.ReactElement,
}> | [] => {
  const buttonList = []
  if (formGroups) {
    for (const group of formGroups) {
      buttonList.push({
        name: group.title,
        ref: group.ref,
        element: (
          <AvatarIconWithStatusWrapper>
            {fetchCorrectStatusIcon(validateSections(errors, group, isSubmitted))}
            <AvatarIconWithStatusContent>
              {group.icon}
            </AvatarIconWithStatusContent>
          </AvatarIconWithStatusWrapper>
        ),
      })
    }
  }
  return buttonList
}

export const getFormConfig = (recordType: string | undefined): Array<FormConfig>|null => {
  switch (recordType) {
  case 'organisation':
    return ORGANISATION_FORM_ELEMENTS
  case 'site':
    return SITE_FORM_ELEMENTS
  case 'service':
    return SERVICE_FORM_ELEMENTS
  default:
    return null
  }
}

export const fetchRecordIcon = (recordType: string | undefined, size: SizeProp, color?: string): React.ReactElement => {
  switch (recordType) {
  case 'site':
    return <FontAwesomeIcon size={size} icon={faHome as IconProp} color={color || '#4F4F4F'} />
  case 'service':
    return <FontAwesomeIcon size={size} icon={faUsersCog as IconProp} color={color || '#4F4F4F'}/>
  case 'organisation':
    return <FontAwesomeIcon size={size} icon={faBuilding as IconProp} color={color || '#4F4F4F'}/>
  default:
    return <FontAwesomeIcon size={size} icon={faUsersCog as IconProp} color={color || '#4F4F4F'}/>
  }
}

export const fetchCorrectIdToDisplay = (
  recordId: number | null,
  issID: number | null | undefined,
): number | string | null => {
  return issID || (recordId && `SP_${recordId}`) || null
}

export const AddNewButton = ({ record }: {record: string}): React.ReactElement | null => {
  return !UNABLE_TO_ADD_RECORD_TYPES.includes(record)
    ? (
      <Button primary to={`/records/${record}/add`} >
          Add {getPageType(record)}
      </Button>
    )
    : null
}

/**
 * Checks ABN for validity using the published ABN checksum algorithm.
 * @author Guy Carpenter
 * @license http://www.clearwater.com.au/code None
 * @param  {String|Number} value abn to validate
 * @return {Boolean} Is ABN Valid
 */
export const validateABN = (value: string): boolean => {
  if (!value) {
    return true
  }
  const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19]
  const abn = value.replaceAll(/[^\d]/g, '')
  let result = false

  // check length is 11 digits
  if (abn.length === 11) {
    // apply ato check method
    let sum = 0
    let weight

    for (let index = 0; index <= weights.length - 1; index++) {
      weight = weights[index]
      const digit: number = parseInt(abn[index]) - (index ? 0 : 1)
      sum += weight * digit
    }
    result = sum % 89 === 0
  }
  return result
}

/***
 * This method checks that the phone number meets a set of specific criteria defined in the updaters training
 *
 *  - It can't be one of these [1, 2, 4, 5, 8, 9] with exceptions for 3 and 7-digit numbers
 *  - If it's only three digits it has to be equal to '000'
 *  - If it's six digits it needs to start with 13
 *  - If it's seven digits it needs to start with 180 (toll-free number)
 */
export const isValidServiceProviderPhoneNumber = (value: string): boolean | string => {
  const errorMessage = 'That does not look like a phone number, please try again'
  const stripedValue = value.replaceAll(/[(.)\s]+/g, '')

  const validCharacters = /^[\d()+\sds]+$/
  // if the phone number contains any characters other than digits,
  // spaces, or the characters '(', ')' or '+', return an error message
  if (!validCharacters.test(value)) return errorMessage

  const inValidNumberLength = [1, 2, 4, 5, 8, 9]
  const exceptionLengths = [3, 6, 7]
  if (!isNaN(parseInt(stripedValue))) {
    if (exceptionLengths.includes(stripedValue.length)) {
      if (stripedValue.length === 7 && stripedValue.substring(0, 3) === '180') {
        return true
      } else if (stripedValue.length === 6 && stripedValue.substring(0, 2) === '13') {
        return true
      } else if (stripedValue.length === 3 && stripedValue === '000') {
        return true
      }
      return errorMessage
    }
    return !inValidNumberLength.includes(stripedValue.length) || errorMessage
  }
  return errorMessage
}

const loadParentValue = (
  parentValues: SPUDSiteRecordDetails,
  key: string,
  externalForm: boolean,
  openingHours: Array<OpenHoursType>,
): string | React.ReactElement => {
  if (Object.keys(parentValues).includes(key)) {
    switch (key) {
    case 'web':
      // Only render the header and value if parentValues.web has a value
      if (parentValues.web) {
        return (
          <>
            <Title level={4}>➥ Site Website Details</Title>
            <span style={{ color: '#767676', fontSize: '13px' }}>
              {parentValues.web}
            </span>
          </>
        )
      }
      return ''
    case 'opening_hours':
      return (
        <SiteOpeningHours
          siteOpeningHours={parentValues.opening_hours}
          bulkUpdateForm={externalForm}
          emptyServiceTimes={openingHours.length === 0}
        />
      )

    default:
      return ''
    }
  }
  return ''
}

export const fetchCorrectComponent = (
  formElement: Field,
  draftValidation: boolean,
  userRole: 'Administrator'|'Updater'|'Editor'|'public',
  formData: SPUDRecord<SPUDSiteRecordDetails | SPUDOrganisationRecordDetails | SPUDServiceRecordDetails>,
  recordType: string | undefined,
  externalForm: boolean,
  field?: ControllerRenderProps | null,
  getValues?: <T extends Option[] | Option | string | null>(name: string) => T,
  setValue?: (name: string, value: unknown) => void,
  siteData?:SPUDSiteRecordDetails,
  linkedRecords?: Array<LinkedRecord>,
  formSubmitted?: boolean,
  bulkUpdateChanges?: BulkServiceProviderUpdateType,
  loadingForm = false,
  resetBulkUpdateRecordChanges = false,
  setResetBulkUpdateRecordChanges?: Dispatch<SetStateAction<boolean>>,
  ageGroups?: {
    data: {
      results: Array<AgeGroupType>
      next: string | null
    }
  },
): React.ReactElement => {
  // return an empty fragment if the field is visible to authorised users and
  // the logged-in user is not authorised to see it.
  if (formElement?.authorisedUserTypes && !formElement?.authorisedUserTypes?.includes(userRole)) {
    return <></>
  }
  const disabled = !!(formData.id && !formData.is_active)
  switch (formElement.type) {
  case 'multiselect':
    if (externalForm) {
      field = {
        ...field,
        onChange: (value) => {
          setValue?.(formElement.name, value)
        },
      } as ControllerRenderProps<FieldValues, string>
    }
    return (
      <>
        <SPUDAutoComplete
          label={formElement.label}
          highlight={formElement.highlight}
          options={formElement.options}
          fetchOptionsAsync={formElement.optionFunction}
          selectedOption={getValues?.(formElement.name)}
          isMulti={true}
          recordType={recordType}
          fieldName={formElement.name}
          createNewFunction={formElement?.createNewRecord}
          canCreateNew={formElement?.canCreateNewRecord}
          asyncParams={formElement?.asyncParams}
          formatDisplayName={formElement?.formatDisplayName}
          formatValueName={formElement?.formatValueName}
          formData={formData}
          disabled={disabled}
          linkedRecords={linkedRecords}
          externalForm={externalForm}
          {...field}
        />
        {bulkUpdateChanges &&
          <BulkUpdateRecordChangeReview
            bulkUpdateChanges={bulkUpdateChanges}
            setValue={setValue}
            fieldKey={formElement.name}
            fieldLabel={formElement.label}
            currentFieldValue={field?.value}
            resetBulkUpdateRecordChanges={resetBulkUpdateRecordChanges}
            setResetBulkUpdateRecordChanges={setResetBulkUpdateRecordChanges}
            ageGroups={ageGroups}
          />}
      </>
    )
  case 'dropdown':
    return (
      <>
        <SPUDAutoComplete
          label={formElement.label}
          highlight={formElement.highlight}
          options={formElement.options}
          fetchOptionsAsync={formElement.optionFunction}
          selectedOption={field?.value}
          isMulti={false}
          recordType={recordType}
          fieldName={formElement.name}
          createNewFunction={formElement?.createNewRecord}
          canCreateNew={formElement?.canCreateNewRecord}
          asyncParams={formElement?.asyncParams}
          formatDisplayName={formElement?.formatDisplayName}
          formatValueName={formElement?.formatValueName}
          formData={formData}
          disabled={disabled}
          linkedRecords={linkedRecords}
          externalForm={externalForm}
          onChange={(value) => {
            setValue?.(formElement.name, value)
          }}
        />
        {bulkUpdateChanges &&
          <BulkUpdateRecordChangeReview
            bulkUpdateChanges={bulkUpdateChanges}
            setValue={setValue}
            fieldKey={formElement.name}
            fieldLabel={formElement.label}
            currentFieldValue={field?.value}
            resetBulkUpdateRecordChanges={resetBulkUpdateRecordChanges}
            setResetBulkUpdateRecordChanges={setResetBulkUpdateRecordChanges}
          />}
      </>
    )
  case 'customComponent':
    return <>
      <CustomComponent
        customComponent={formElement.customComponent?.name as string}
        props={{
          ...formElement.customComponent?.props,
          callback: setValue,
          formKey: formElement.name,
          value: getValues?.(formElement.name),
          draftValidation: draftValidation,
          onFormSubmitted: formSubmitted,
          highlight: formElement?.highlight,
          siteValues: siteData || formData.site,
          disabled: disabled,
          externalForm: externalForm,
          loadingForm,
          bulkUpdateChanges,
        }}
      />
    </>
  case 'textarea':
    return (
      <>
        <SPUDTextArea
          field={field}
          label={formElement.label}
          highlight={formElement.highlight}
          canShowLineBreaks={!!(formElement.serviceProviderEditable ? false : formElement?.canShowLineBreaks)}
          disabled={false}
          minHeight={formElement?.minHeight}
          onChange={(evt) => setValue?.(formElement.name, evt.target.value)}
        />
        {formData.site && recordType === 'service' && <Text>
          {loadParentValue(
            siteData || formData.site,
            formElement.name === 'opening_hours_simple' ? 'opening_hours' : formElement.name,
            externalForm,
            formData.opening_hours as Array<OpenHoursType>,
          )}
        </Text>}
        {bulkUpdateChanges &&
          <BulkUpdateRecordChangeReview
            bulkUpdateChanges={bulkUpdateChanges}
            setValue={setValue}
            fieldKey={formElement.name}
            fieldLabel={formElement.label}
            currentFieldValue={field?.value}
            allowChange={formElement.allowAcceptServiceProviderChange}
            resetBulkUpdateRecordChanges={resetBulkUpdateRecordChanges}
            setResetBulkUpdateRecordChanges={setResetBulkUpdateRecordChanges}
          />}
      </>
    )
  case 'toggle':
    return (
      <SPUDToggle
        padding='1.5rem 0 0'
        label={<Title level={4} marginTop={'0px'}>{formElement.label}</Title>}
        checked={!!getValues?.(formElement.name)}
        onChange={(checked: boolean) =>
          setValue?.(formElement.name, checked)
        }
        onKeyDown={(key: React.KeyboardEvent<HTMLInputElement>) => {
          const checked = getValues?.(formElement.name)
          if (key.key === 'Enter') {
            setValue?.(formElement.name, !checked)
          }
        }}
        offColor="#E3EDFA"
        onBlur={field?.onBlur}
        // Typecasting because the controller ref is a callback while the switch is an Object
        ref={field?.ref as React.RefCallback<ReactSwitch> & React.RefObject<ReactSwitch>}
        name={field?.name}
        disabled={disabled}
      />
    )
  case 'radio':
    return (
      <SPUDRadioButtonGroup
        title={<Title level={4}>{formElement.label}</Title>}
        options={formElement.options}
        name={formElement.name}
        field={field}
      />
    )
  case 'date':
    return <>
      <Title level={4}>{formElement.label}</Title>
      <SPUDDatePicker
        formElement={formElement}
        field={field}
        fullWidth={true}
        disabled={disabled}
      />
    </>
  default:
    if (formElement.mask) {
      return (
        <>
          <InputMask
            mask={formElement?.mask}
            {...field}
          >
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <SPUDInputField
              type={formElement.type}
              label={formElement.type !== 'hidden' && <Title level={4}>{formElement.label}</Title>}
              required={formElement.rules?.required as boolean}
              hideAsterisk={formElement.hideAsterisk}
              highlight={formElement.highlight && !field?.value}
              placeholder={formElement.label}
              fullwidth
              value={field?.value}
            />
          </InputMask>
          {formData.site && recordType === 'service' && <Text>
            {loadParentValue(
              siteData || formData.site,
              formElement.name === 'opening_hours_simple' ? 'opening_hours' : formElement.name,
              externalForm,
              formData.opening_hours as Array<OpenHoursType>,
            )}
          </Text>}
          {bulkUpdateChanges && formElement?.allowAcceptServiceProviderChange &&
            <BulkUpdateRecordChangeReview
              bulkUpdateChanges={bulkUpdateChanges}
              setValue={setValue}
              fieldKey={formElement.name}
              fieldLabel={formElement.label}
              currentFieldValue={field?.value}
              allowChange={formElement.allowAcceptServiceProviderChange}
              resetBulkUpdateRecordChanges={resetBulkUpdateRecordChanges}
              setResetBulkUpdateRecordChanges={setResetBulkUpdateRecordChanges}
            />}
        </>
      )
    } else {
      return (
        <>
          {externalForm
            ? (
              <SPUDInputField
                type={formElement.type}
                required={formElement.rules?.required as boolean}
                hideAsterisk={formElement.hideAsterisk}
                highlight={formElement.highlight && !field?.value}
                placeholder={formElement.label}
                label={formElement.type !== 'hidden' && <Title level={4}>{formElement.label}</Title>}
                fullwidth
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  setValue?.(formElement.name, evt.target.value)
                }}
                value={field?.value}
                name={field?.name}
                disabled={disabled}
                ref={field?.ref}
                onBlur={field?.onBlur}
              />
            )
            : (
              <SPUDInputField
                type={formElement.type}
                required={formElement.rules?.required as boolean}
                hideAsterisk={formElement.hideAsterisk}
                highlight={formElement.highlight && !field?.value}
                placeholder={formElement.label}
                label={formElement.type !== 'hidden' && <Title level={4}>{formElement.label}</Title>}
                fullwidth
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  setValue?.(formElement.name, evt.target.value)
                }}
                value={field?.value}
                name={field?.name}
                {...field}
              />
            )}
          {formData.site && recordType === 'service' && <Text>
            {loadParentValue(
              siteData || formData.site,
              formElement.name === 'opening_hours_simple' ? 'opening_hours' : formElement.name,
              externalForm,
              formData.opening_hours as Array<OpenHoursType>,
            )}
          </Text>}
          {bulkUpdateChanges && formElement?.allowAcceptServiceProviderChange &&
            <BulkUpdateRecordChangeReview
              bulkUpdateChanges={bulkUpdateChanges}
              setValue={setValue}
              fieldKey={formElement.name}
              fieldLabel={formElement.label}
              currentFieldValue={field?.value}
              allowChange={formElement.allowAcceptServiceProviderChange}
              resetBulkUpdateRecordChanges={resetBulkUpdateRecordChanges}
              setResetBulkUpdateRecordChanges={setResetBulkUpdateRecordChanges}
            />}
        </>
      )
    }
  }
}

export const formatErrorMessage = (
  methods: UseFormReturn<{[p: string]: unknown}>,
  label: string,
  fieldName: string,
) => {
  if (!methods.formState.errors?.[fieldName]?.message) {
    return `${label} is ${methods.formState.errors?.[fieldName]?.type || 'required'}`
  } else {
    return `${methods.formState.errors?.[fieldName]?.message}`
  }
}

export const hasFormBeenSubmitted = (
  recordId: string | number | null | undefined,
  methods: UseFormReturn<{[p: string]: unknown}>): boolean => {
  return !!(methods.formState.isValidating || methods.formState.isSubmitted ||
    (typeof recordId === 'string' && recordId))
}

/**
 * we need to specify default values for each field
 *
 * This returns an object of key value pairs where the key is name used
 * by react-hooks-form to keep track of the fields, and the value is default value of what that form should be
 * e.g:
 * {
 *   {'name': 'New name},
 *   {'phoneNumber', 123}
 * }
 * */
// disabled because shape of the form isn't known till runtime
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const constructDefaultFormData = (recordType?: string, isBulkAutoUpdate = false): { [x: string]: any; } => {
  const formConfig = getFormConfig(recordType) || []
  if (formConfig.length) {
    const formatFormConfigArray = formConfig?.map(
      formGroup => formGroup.fields.map(
        formComp => ({ [formComp?.name]: formComp?.defaultValue }),
      ),
    )

    if (!isBulkAutoUpdate) {
      return formatFormConfigArray.flat().reduce(
        (previousValue, currentValue) => (
          { ...previousValue, ...currentValue }
        ),
      )
    } else {
      let defaultValues = formatFormConfigArray.flat().reduce(
        (previousValue, currentValue) => (
          { ...previousValue, ...currentValue }
        ),
      )
      defaultValues = {
        ...defaultValues,
        bulk_update_confirm_no_changes: false,
        bulk_update_comment: '',
        opening_hours_simple: '',
      }
      if (recordType === 'service') {
        defaultValues = {
          ...defaultValues,
          bulk_update_delete: false,
        }
      }
      return defaultValues
    }
  }
  return {}
}

export const fetchAccessId = () => {
  return sessionStorage.getItem('bulk_update_code')
}

export const fixAgeGroups = (ageGroups: Array<string>, ageGroupsResponse: {
  data: {
    results: Array<AgeGroupType>
    next: string | null
  }
}): Array<string> => {
  return ageGroups.map(ageGroup => {
    try {
      const foundAgeGroup = ageGroupsResponse.data.results.find(
        (group: AgeGroupType) => group.id === parseInt(ageGroup),
      )
      if (foundAgeGroup) {
        return foundAgeGroup.name
      }
      return ageGroup
    } catch (e) {
      return ageGroup
    }
  })
}
