// This component is to force a structure on both the existing service + new service changes in the review & submit window
const fixedFieldOrder = [
  'name',
  'service_types',
  'description',
  'funding_body',
  'eligibility_info',
  'ineligibility_info',
  'age_groups',
  'target_gender',
  'languages',
  'phones',
  'emails',
  'web',
  'social_media',
  'opening_hours',
  'opening_hours_simple',
  'availability',
  'assessment_criteria',
  'catchment_description',
  'bulk_update_comment',
  'details',
  'is_free_or_low_cost',
  'billing_method',
  'accreditation',
  'practitioner_gender',
  'crisis_keywords',
  'intake_info',
  'intake_point',
  'catchment',
  'referral_info',
  'special_requirements',
  'cost',
  'temporary_until',
  'sa_priority_service',
  'cald_specific',
  'is_lgbtiqa_plus_specific',
  'show_in_askizzy_health',
  'comment',
  'accepts_healthcare_cards',
  'ndis_approved',
  'statewide',
  'after_hours',
  'site',
]

export default fixedFieldOrder
